import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import RightLineArrowIcon from "../components/Icons/RightLineArrowIcon"
import { Section } from "../components/Section"
import PrimaryButton from "../components/Button/PrimaryButton"
import CallNow from "../components/CallNow"
import BreakpointUp from "../components/Media/BreakpointUp"
import { StaticImage } from "gatsby-plugin-image"

const NodataBackground = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    display: none;
    ${BreakpointUp.md`
      display:block;
    `}
  }
`
const NodataPanel = styled.div`
  text-align: center;
  padding: 60px 0;
  ${BreakpointUp.md`
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    padding: 0;
  `}
`
const NodataBody = styled.div`
  position: relative;
`
const NodataHeading = styled.div`
  ${BreakpointUp.md`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    top: -190px;
  `}
  ${BreakpointUp.lg`
    top: -220px;
  `}
  ${BreakpointUp.xl`
    top: -248px;
  `}
  h1 {
    color: rgba(11, 97, 155, 0.15);
    margin-bottom: 0;
    ${BreakpointUp.lg`
      font-size: 80px;
      line-height: 60px;
    `}
  }
`
const NodataContent = styled.div`
  max-width: 390px;
  width: 100%;
  margin: 0 auto;
  ${BreakpointUp.md`
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
  `}
  ${BreakpointUp.lg`
    margin: 50px 0;    
  `}
  ${BreakpointUp.xl`
    margin: 65px 0;    
  `}
  h2 {
    color: #0b619b;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 28px;
    ${BreakpointUp.lg`
      font-size: 18px;
      line-height: 30px;
    `}
  }
  .btn {
    flex-direction: row-reverse;
    .icon {
      + .text {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
`

const NotFoundPage = ({ location }) => (
  <Layout location={location} >
    <Seo title="Page not found - Coast to Coast Carports" description="Page not found - Coast to Coast Carports" />
    <Section pt="215px" pb="0" xpt="54px" xpb="0" bgColor="#F2F4F9">
      <div className="container">
        <NodataBackground>
          <StaticImage src="../images/not-found-bg.png" alt="not found" />
          <NodataPanel>
            <NodataBody>
              <NodataHeading>
                <h1>404 ERROR</h1>
              </NodataHeading>
              <NodataContent>
                <h2>Page Not Found</h2>
                <p>
                  Sorry, we can’t find the page you are looking for click here
                  to go back to the home page.
                </p>
                <Link to="/">
                  <PrimaryButton
                    icon={<RightLineArrowIcon />}
                    text="Go To Home"
                  />
                </Link>
              </NodataContent>
            </NodataBody>
          </NodataPanel>
        </NodataBackground>
      </div>
    </Section>
    <CallNow />
  </Layout>
)

export default NotFoundPage
